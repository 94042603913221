<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    v-if="trialModalVisible"
    class="fixed inset-0 overflow-y-auto"
    :class="safeToShow ? 'z-10' : '-z-20'"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity duration-200 ease-in-out" aria-hidden="true"
        :class="safeToShow ? 'opacity-100' : 'opacity-0'"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline"
        :class="safeToShow ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div
            v-if="getUserX.emailVerifiedAt"
            class="flex flex-col"
          >
            <div class="uppercase text-center text-lg">
              Subscribe to Premium Plan
            </div>

            <div class="flex justify-center items-center mt-4">
              <span class="text-gray-500 text-xl">
                &pound;
              </span>
              <span class="text-black text-4xl font-bold mx-2">
                8
              </span>
              <span class="text-gray-500 text-xl">
                / month
              </span>
            </div>

            <div class="uppercase mt-8">
              What's Included
            </div>

            <div class="flex items-center mt-4">
              <div class="flex-shrink text-il-teal font-bold p-2">
                <i class="fas fa-check"></i>
              </div>
              <div>
                A maximum of 3 companies to manage
              </div>
            </div>

            <div class="flex items-center">
              <div class="flex-shrink text-il-teal font-bold p-2">
                <i class="fas fa-check"></i>
              </div>
              <div>
                A maximum of 10 clients per company
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex-shrink text-il-teal font-bold p-2">
                <i class="fas fa-check"></i>
              </div>
              <div>
                Any Invoice accent colors
              </div>
            </div>
            <div class="flex items-center">
              <div class="flex-shrink text-il-teal font-bold p-2">
                <i class="fas fa-check"></i>
              </div>
              <div>
                Send Invoice to Client's Email
              </div>
            </div>

            <div
              v-if="!getSubscribedBeforeX && !getUserX.trialEndsAt"
              class="flex justify-center text-xs mt-8 uppercase underline"
            >
              Start with a 30 day trial. No Payment Card needed
            </div>
          </div>

          <div
            v-else
          >
            Please verify your Email first
          </div>

        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            v-if="getUserX.emailVerifiedAt && !getSubscribedBeforeX && !getUserX.trialEndsAt"
            @click="startTrial"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-il-dark text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            :class="trialRequestLoading ? 'cursor-default' : ''"
          >
            <span v-if="trialRequestLoading">
              <i class="fas fa-circle-notch fa-spin text-white"></i>
            </span>
            <span v-else>
              Start Trial
            </span>
          </button>

          <button
            v-if="getUserX.emailVerifiedAt && getUserX.trialEndsAt && !getIsOnTrialX"
            @click="startSubscription"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-il-dark text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            :class="startRequestLoading ? 'cursor-default' : ''"
          >
            <span v-if="startRequestLoading">
              <i class="fas fa-circle-notch fa-spin text-white"></i>
            </span>
            <span v-else>
              Subscribe
            </span>
          </button>

          <button
            v-if="!startRequestLoading"
            @click="hideTrialModal"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { loadStripe } from '@stripe/stripe-js';
  import { mapGetters, mapMutations } from 'vuex';
  import userRequests from '../../helpers/userRequests';

  export default {
    props: ['trialModalVisible'],
    emits: ['update:trialModalVisible'],
    data() {
      return {
        trialRequestLoading: false,
        startRequestLoading: false,
        safeToShow: false,
        stripe: {},
        cardElement: {},
        customer: {
          first_name: '',
          last_name: '',
          email: '',
          address: '',
          city: '',
          state: '',
          zip_code: ''
        },
      }
    },
    methods: {
      ...mapMutations([
        'setIsSubscribedX',
        'setTrialEndsAtX',
        'setIsOnTrialX',
        'setNextPaymentDateX'
      ]),
      startTrial() {
        if(!this.trialRequestLoading) {
          this.trialRequestLoading = true;
          userRequests.startTrial()
          .then((response) => {
            this.popSuccessMessageMX(response.data.message);
            this.setIsOnTrialX(true);
            this.setTrialEndsAtX(response.data.trial_ends_at);
            this.trialRequestLoading = false;
            this.hideTrialModal();
          }).catch(error => {
            this.trialRequestLoading = false;
          });
        }
      },
      startSubscription() {
        if(!this.startRequestLoading) {
          this.startRequestLoading = true;
          userRequests.startSubscription()
          .then((response) => {
            this.setIsSubscribedX(true);
            this.popSuccessMessageMX(response.data.message);
            this.setNextPaymentDateX(response.data.next_payment_date);
            this.startRequestLoading = false;
            this.hideTrialModal();
          }).catch(error => {
            this.startRequestLoading = false;
            this.popErrorMX(error.response.data.message);
          });
        }
      },
      hideTrialModal() {
        this.safeToShow = false;
        setTimeout(() => {
          this.updateValue('trialModalVisible')
        }, 300);
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop,value);
      }
    },
    computed: {
      ...mapGetters([
        'getUserX',
        'getSubscribedBeforeX',
        'getDefaultPaymentX',
        'getIsOnTrialX'
      ])
    },
    watch: {
      trialModalVisible: function (val) {
        if(val == true) {
          setTimeout(() => {
            this.safeToShow = true;
          }, 100);
        }
      }
    }
  }
</script>