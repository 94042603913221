<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    v-if="paymentMethodModalVisible"
    class="fixed inset-0 overflow-y-auto"
    :class="safeToShow ? 'z-20' : '-z-20'"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity duration-200 ease-in-out" aria-hidden="true"
        :class="safeToShow ? 'opacity-100' : 'opacity-0'"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline"
        :class="safeToShow ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col">
            <label for="name" class="text-il-dark font-bold uppercase text-xs">Name on Card</label>
            <input
              type="text"
              id="name"
              name="name"
              class="w-full rounded border border-gray-300 focus:border-il-dark text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              v-model="customer.name"
              :disabled="paymentMethodRequestLoading"
            >

            <label for="email" class="text-il-dark font-bold uppercase text-xs mt-4">Email</label>
            <input
              type="text"
              id="email"
              name="email"
              class="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              :value="getUserX.email"
              disabled
            >

            <label for="address" class="text-il-dark font-bold uppercase text-xs mt-4">Address Line 1</label>
            <input
              type="text"
              id="address"
              name="address"
              class="w-full rounded border border-gray-300 focus:border-il-dark text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              v-model="customer.address"
              :disabled="paymentMethodRequestLoading"
            >

            <label for="card-element" class="text-il-dark font-bold uppercase text-xs mt-4">Card Details</label>
            <div id="card-element"></div>

          </div>
        </div>

        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="getPaymentIntent"
            :disabled="paymentMethodRequestLoading"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-il-dark text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <span
              v-if="!paymentMethodRequestLoading"
            >
              Update Payment Method
            </span>
            <span
              v-else
            >
              <i class="fas fa-circle-notch fa-spin text-white"></i>
            </span>
          </button>
          <button
            v-if="!paymentMethodRequestLoading"
            @click="hidePaymentMethodModal"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import userRequests from '../../helpers/userRequests';

  export default {
    props: ['paymentMethodModalVisible'],
    emits: ['update:paymentMethodModalVisible'],
    mounted() {
      // this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);

    },
    data() {
      return {
        paymentMethodRequestLoading: false,
        safeToShow: false,
        stripe: {},
        cardElement: {},
        customer: {
          address: ''
        },
        // paymentProcessing: false,
        clientSecret: null
      }
    },
    methods: {
      ...mapMutations([
        'setDefaultPaymentX',
        'setIsOnTrialX',
        'setIsSubscribedX'
      ]),
      getPaymentIntent() {
        if(!this.paymentMethodRequestLoading) {
          this.paymentMethodRequestLoading = true;
          userRequests.getPaymentIntent()
          .then((response) => {
            this.clientSecret = response.data.client_secret;
            this.savePaymentMethod();
          })
          .catch((error) => {
            this.paymentMethodRequestLoading = false;
            console.error(error);
          });
        }
      },
      async savePaymentMethod() {
        const { setupIntent, error } = await this.getStripeX.confirmCardSetup(
          this.clientSecret, {
            payment_method: {
              card: this.cardElement,
              billing_details: {
                name: this.customer.name,
                email: this.getUserX.email,
                address: {
                  line1: this.customer.address
                }
              }
            }
          }
        );

        if (error) {
          this.paymentMethodRequestLoading = false;
          this.popErrorMX(error.message);
            // Display "error.message" to the user...
        } else {
          userRequests.savePaymentMethod(setupIntent.payment_method)
          .then((response) => {
            this.paymentMethodRequestLoading = false;
            this.hidePaymentMethodModal();
            this.setDefaultPaymentX(response.data.default_payment);
            this.setIsOnTrialX(response.data.is_on_trial);
            this.setIsSubscribedX(response.data.is_subscribed);
            this.popSuccessMessageMX(response.data.message);
          }).catch(error => {
            this.paymentMethodRequestLoading = false;
          });
        }
      },
      hidePaymentMethodModal() {
        this.safeToShow = false;
        setTimeout(() => {
          this.updateValue('paymentMethodModalVisible')
        }, 300);
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop,value);
      }
    },
    computed: {
      ...mapGetters([
        'getStripeX',
        'getUserX'
      ])
    },
    watch: {
      paymentMethodModalVisible: function (val) {
        if(val == true) {
          setTimeout(() => {
            this.safeToShow = true;

            const elements = this.getStripeX.elements();
            this.cardElement = elements.create('card', {
              classes: {
                base: 'text-il-dark rounded border border-gray-300 focus:border-il-dark text-base outline-none p-3 leading-8 transition-colors duration-200 ease-in-out'
              }
            });
            this.cardElement.mount('#card-element');
          }, 100);
        }
      }
    }
  }
</script>