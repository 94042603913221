<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    v-if="cancelSubscriptionModalVisible"
    class="fixed inset-0 overflow-y-auto"
    :class="safeToShow ? 'z-10' : '-z-20'"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity duration-200 ease-in-out" aria-hidden="true"
        :class="safeToShow ? 'opacity-100' : 'opacity-0'"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline"
        :class="safeToShow ? 'opacity-100 translate-y-0 sm:scale-100' : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="flex flex-col">
            <div class="uppercase text-center text-lg">
              Cancel your Premium Plan
            </div>

            <div>
              Your account will be downgraded to the <strong>Free Plan</strong> which allows for <strong>1 Company</strong> and <strong>3 Clients</strong>.
            </div>

            <div class="mt-4">
              Choose the Company and Clients you wish to keep after cancellation
            </div>

            <div class="text-il-dark text-xs uppercase font-bold mt-4">
              Company
            </div>
            <div class="flex flex-col">
              <div
                v-for="company in getUserCompaniesX"
                :key="company.id"
                class="flex items-center"
              >
                <input class="mr-2" type="radio" :id="'company_' + company.id" :value="company.id" v-model="selectedCompanyId">
                <label :for="'company_' + company.id">{{ company.name }}</label>
              </div>
            </div>

            <div class="text-il-dark text-xs uppercase font-bold mt-4">
              Clients
            </div>
            <div
              v-if="selectedCompanyClients.length > 0"
              class="flex flex-col h-150px overflow-x-auto"
            >
              <div
                v-for="client in selectedCompanyClients"
                :key="client.id"
                class="flex items-center"
              >
                <input class="mr-2" type="checkbox" :id="'client_' + client.id" :value="client.id" v-model="selectedClientIds">
                <label :for="'client_' + client.id">{{ client.name }}</label>
              </div>
            </div>
            <div class="h-150px" v-else>
              This company has no clients attached
            </div>
          </div>
        </div>

        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="cancelSubscription"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            :class="cancelRequestLoading ? 'cursor-default' : ''"
          >
            <span v-if="cancelRequestLoading">
              <i class="fas fa-circle-notch fa-spin text-white"></i>
            </span>
            <span v-else>
              Cancel Subscription
            </span>
          </button>
          <button
            @click="hideCancelSubscriptionModal"
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Never Mind
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import { loadStripe } from '@stripe/stripe-js';
  import { mapGetters, mapMutations } from 'vuex';
  import userRequests from '../../helpers/userRequests';
  import lodash from 'lodash';

  export default {
    props: ['cancelSubscriptionModalVisible'],
    emits: ['update:cancelSubscriptionModalVisible'],
    mounted() {
      this.selectedCompanyId = this.getUserCompaniesX[0]['id'];
    },
    data() {
      return {
        cancelRequestLoading: false,
        safeToShow: false,
        selectedCompanyId: null,
        selectedClientIds: []
      }
    },
    methods: {
      ...mapMutations([
        'loginUserX',
        'setIsSubscribedX',
        'setDefaultPaymentX',
        'setSubscribedBeforeX'
      ]),
      cancelSubscription() {
        if(!this.cancelRequestLoading) {
          this.cancelRequestLoading = true;
          userRequests.cancelSubscription(this.selectedCompanyId, this.selectedClientIds)
          .then((response) => {
            this.popSuccessMessageMX(response.data.message);
            this.cancelRequestLoading = false;
            this.setIsSubscribedX(false);
            this.setDefaultPaymentX(null);
            this.setSubscribedBeforeX(true);
            this.loginUserX(response.data.user);
            this.hideCancelSubscriptionModal();
          }).catch(error => {
            this.cancelRequestLoading = false;
            if(error.response.data.errors) {
              this.popErrorsMX(error.response.data.errors);
            }
          });
        }
      },
      hideCancelSubscriptionModal() {
        this.safeToShow = false;
        this.selectedClientIds = [];
        setTimeout(() => {
          this.updateValue('cancelSubscriptionModalVisible')
        }, 300);
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop,value);
      }
    },
    computed: {
      ...mapGetters([
        'getUserX',
        'getUserCompaniesX',
        'getUserCurrentCompanyX',
        'getSubscribedBeforeX'
      ]),
      selectedCompanyClients() {
        const selectedCompanyId = this.selectedCompanyId;
        var company = lodash.find(this.getUserCompaniesX, function(c) {
          return c.id == selectedCompanyId;
        });
        return company.clients;
      }
    },
    watch: {
      cancelSubscriptionModalVisible: function (val) {
        if(val == true) {
          setTimeout(() => {
            this.safeToShow = true;
          }, 100);
        }
      },
      selectedCompanyId: function() {
        this.selectedClientIds = [];
      }
    }
  }
</script>