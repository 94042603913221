<template>
  <div class="flex flex-col w-full m-8 bg-white rounded-lg p-4">

    <div
      class="flex justify-center text-2xl text-il-dark font-bold"
    >
      {{ getUserX.name }}
    </div>

    <div
      class="text-center text-gray-400 uppercase text-sm mt-2"
    >
      your profile settings and subscription
    </div>


    <div class="flex flex-wrap mt-4">
      <div class="flex flex-col w-full lg:w-6/12 p-2">
        <SubscriptionInfo />
      </div>

      <div class="flex flex-col w-full lg:w-6/12 p-2">
        <AccountInfo />
      </div>

    </div>

  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import SubscriptionInfo from '../../components/Profile/SubscriptionInfo';
  import AccountInfo from '../../components/Profile/AccountInfo';

  export default {
    components: {
      SubscriptionInfo,
      AccountInfo
    },
    mounted() {
    },
    methods: {
      ...mapMutations([
        'setUserCurrentCompanyNameX',
        'setUserCurrentCompanyX'
      ])
    },
    computed: {
      ...mapGetters([
        'getUserX'
      ]),
    },
  }
</script>