<template>
  <div
    class="text-white bg-il-dark text-center font-bold text-xl p-2 uppercase w-full rounded-t-lg"
  >
    Account
  </div>

  <div class="flex flex-col p-4 shadow-lg rounded-b-lg">

    <div
      class="text-il-dark font-bold uppercase text-xs"
    >
      Email Verification
    </div>
    <div
      v-if="!getUserX.emailVerifiedAt"
      class="mb-4 flex flex-col"
    >
      <div class="animate__slow animate__animated animate__flash border border-red-600 text-red-600 bg-red-100 p-2 text-xs uppercase">
        Please verify your account to gain access to all sections of the website
      </div>
      <div class="flex">
        <input
          class="w-full text-lg outline-none border border-gray-400 focus:border-il-dark px-2 py-1 transition-all duration-200 ease-in-out"
          type="text"
          placeholder="Email Verification Code"
          v-model="emailVerificationCode"
        />
        <div
          @click="verifyEmail"
          class="bg-il-dark p-2 text-white px-4 uppercase transition-all duration-200 ease-in-out"
          :class="stuffLoading ? '' : 'cursor-pointer'"
        >
          <span v-if="!stuffLoading">
            Verify
          </span>
          <span v-else>
            <i class="fas fa-circle-notch fa-spin text-white"></i>
          </span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="mb-4"
    >
      <span class="text-il-teal mr-1">
        <i class="fas fa-check-circle"></i>
      </span>
      Email Verified
    </div>

    <div class="text-il-dark font-bold uppercase text-xs">
      Name
    </div>
    <div>
      <input
        class="w-full text-lg outline-none border border-gray-400 focus:border-il-dark px-2 py-1 transition-all duration-200 ease-in-out"
        type="text"
        v-model="name"
        readonly
      />
    </div>

    <div class="text-il-dark font-bold uppercase text-xs mt-4">
      Email
    </div>
    <div>
      <input
        class="w-full text-lg outline-none border border-gray-400 focus:border-il-dark px-2 py-1 transition-all duration-200 ease-in-out"
        type="text"
        v-model="email"
        readonly
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import userRequests from '../../helpers/userRequests';
  import authRequests from '../../helpers/authRequests';

  export default {
    mounted() {
      this.name = this.getUserX.name;
      this.email = this.getUserX.email;
    },
    data() {
      return {
        name: null,
        email: null,
        emailVerificationCode: null,
        stuffLoading: false
      }
    },
    methods: {
      ...mapMutations([
        'setUserCurrentCompanyNameX',
        'setUserCurrentCompanyX',
        'setEmailVerificationDateX'
      ]),
      verifyEmail() {
        if(!this.stuffLoading) {
          this.stuffLoading = true;
          authRequests.verifyEmail(this.emailVerificationCode)
          .then((response) => {
            this.stuffLoading = false;
            this.setEmailVerificationDateX(response.data.verified_at);
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            this.stuffLoading = false;
            if(error.response.data.errors) {
              this.popErrorsMX(error.response.data.errors);
            } else {
              this.popErrorMX(error.response.data.message);
            }
          });
        }
      },
      updateAccountInfo() {
        if(!this.stuffLoading) {
          let that = this;
          this.stuffLoading = true;
          this.userRequests.updateAccountInfo()
          .then((response) => {
            that.stuffLoading = false;
            that.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            that.stuffLoading = false;
            if(error.response.data.status == 'deleted') {
              that.$toast.error(error.response.data.message, {
                position: "bottom"
              });
            }
          });
        }
      }
    },
    computed: {
      ...mapGetters([
        'getUserX',
      ]),
      infoChanged() {
        if(this.name != this.getUserX.name || this.email != this.getUserX.email) {
          return true;
        }
        return false;
      }
    }
  }
</script>

<style>

</style>