<template>
  <div
    class="text-white bg-il-dark text-center font-bold text-xl p-2 uppercase w-full rounded-t-lg"
  >
    Subscription
  </div>

  <SubscriptionTrialModal
    v-model:trialModalVisible="trialModalVisible"
  />

  <PaymentMethodModal
    v-model:paymentMethodModalVisible="paymentMethodModalVisible"
  />

  <CancelSubscriptionModal
    v-model:cancelSubscriptionModalVisible="cancelSubscriptionModalVisible"
  />

  <div class="flex flex-col p-4 shadow-lg rounded-b-lg">
    <div class="text-il-dark font-bold uppercase text-xs">
      Current Plan
    </div>

    <!-- PREMIUM PLAN -->
    <div
      v-if="getIsOnTrialX || getIsSubscribedX || isInGracePeriodMX(getUserX.trialEndsAt)<=0"
      class="flex flex-col"
    >
      <div class="font-bold text-il-teal text-lg">
        Premium
        <span
          v-if="!getIsSubscribedX && !getIsOnTrialX && isInGracePeriodMX(getUserX.trialEndsAt) <= 0"
          class="bg-yellow-200 text-yellow-700 px-4 py-0.5 ml-2 rounded-lg text-sm"
        >
          Trial
        </span>
        <span
          v-if="!getIsSubscribedX && !getIsOnTrialX && isInGracePeriodMX(getUserX.trialEndsAt) <= 0"
          class="bg-red-200 text-red-700 px-4 py-0.5 ml-2 rounded-lg text-sm inline-block"
        >
          Grace Period Ends {{ trialEndGraceDate }}
        </span>
        <span
          v-if="!getIsSubscribedX && getIsOnTrialX"
          class="bg-yellow-200 text-yellow-700 px-4 py-0.5 ml-2 rounded-lg text-sm inline-block"
        >
          Trial Ends {{ trialEndDate }}
        </span>
        <span
          v-if="getIsSubscribedX && !getIsOnTrialX && getNextPaymentDateX"
          class="bg-green-200 text-green-700 px-4 py-0.5 ml-2 rounded-lg text-sm inline-block"
        >
          Next Payment on {{ getNextPaymentDateX }}
        </span>
      </div>

      <div
        v-if="!getIsSubscribedX && !getIsOnTrialX && isInGracePeriodMX(getUserX.trialEndsAt) <= 0"
        class="flex flex-col border border-red-600 bg-red-50 p-2 rounded-lg text-red-600"
      >
        <div>
          Please add a Payment Method before the grace period ends, otherwise you will lose access to your companies and clients.
        </div>
        <div class="mt-2">
          You will still have access to your oldest company with 3 of its oldest clients as shown below:
        </div>

        <div class="font-bold mt-1">
          {{ getUserOldestCompanyX.name }}
        </div>
        <ul>
          <li
            v-for="client in oldestClientsForOldestCompany.slice(0,3)"
            :key="client.id"
            class="list-disc ml-8"
          >
            {{ client.name }}
          </li>
        </ul>
      </div>

      <div class="mt-2 text-il-dark font-bold uppercase text-xs">
        What's included
      </div>
      <div>
        <ul>
          <li>
            <span class="text-il-teal mx-2">
              <i class="fas fa-check"></i>
            </span>
            Maximum 3 companies to manage
          </li>
          <li>
            <span class="text-il-teal mx-2">
              <i class="fas fa-check"></i>
            </span>
            Maximum 10 clients per company
          </li>
          <li>
            <span class="text-il-teal mx-2">
              <i class="fas fa-check"></i>
            </span>
            Any Invoice accent colors
          </li>
          <li>
            <span class="text-il-teal mx-2">
              <i class="fas fa-check"></i>
            </span>
            Send Invoice to Client's Email
          </li>
        </ul>
      </div>

      <div
        v-if="getIsSubscribedX"
        class="flex mt-2"
      >
        <div
          @click="showCancelSubscriptionModal"
          class="rounded-lg text-sm p-1 px-4  transition-all duration-200 ease-in-out bg-gray-300 text-gray-500 hover:bg-red-100 hover:text-red-700 cursor-pointer"
        >
          <div>
            <i class="fas fa-trash"></i>
            <span class="ml-1">
              Cancel Subscription
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="getIsOnTrialX || isInGracePeriodMX(getUserX.trialEndsAt)<=0"
        class="flex mt-2"
      >
        <div
          @click="cancelTrial"
          class="rounded-lg text-sm p-1 px-4  transition-all duration-200 ease-in-out"
          :class="cancelTrialLoading ? 'bg-red-100 text-red-700' : 'bg-gray-300 text-gray-500 hover:bg-red-100 hover:text-red-700 cursor-pointer'"
        >
          <div v-if="!cancelTrialLoading" >
            <i class="fas fa-trash"></i>
            <span class="ml-1">
              Cancel Trial
            </span>
          </div>
          <div v-else>
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </div>
      </div>

    </div>

    <!-- FREE PLAN -->
    <div
      v-if="!getIsOnTrialX && !getIsSubscribedX && isInGracePeriodMX(getUserX.trialEndsAt)==1"
      class="flex flex-col"
    >
      <div class="font-bold text-il-teal text-lg">
        Free
      </div>

      <div class="mt-2 text-il-dark font-bold uppercase text-xs">
        What's included
      </div>
      <div>
        <ul>
          <li>
            <span class="text-il-teal mx-2">
              <i class="fas fa-check"></i>
            </span>
            Maximum 1 company to manage
          </li>
          <li>
            <span class="text-il-teal mx-2">
              <i class="fas fa-check"></i>
            </span>
            Maximum 3 clients per company
          </li>
          <li>
            <span class="text-il-teal mx-2">
              <i class="fas fa-check"></i>
            </span>
            Any Invoice accent colors
          </li>
        </ul>
      </div>

      <div
        v-if="!getSubscribedBeforeX && !getUserX.trialEndsAt"
        class="flex mt-2"
      >
        <div
          @click="showTrialModal"
          class="rounded-lg text-sm p-1 px-4 transition-all duration-200 ease-in-out bg-gray-300 text-gray-500 hover:bg-yellow-100 hover:text-yellow-700 cursor-pointer"
        >
          <div>
            <i class="fas fa-star"></i>
            <span class="ml-1">
              Start Premium Trial for Free
            </span>
          </div>
        </div>
      </div>

      <div
        v-else
        class="flex mt-2"
      >
        <div
          @click="showTrialModal"
          class="rounded-lg text-sm p-1 px-4  transition-all duration-200 ease-in-out bg-gray-300 text-gray-500 hover:bg-yellow-100 hover:text-yellow-700 cursor-pointer"
        >
          <div>
            <i class="fas fa-star"></i>
            <span class="ml-1">
              Subscribe to Premium Plan
            </span>
          </div>
        </div>
      </div>

    </div>

    <!-- PAYMENT METHOD -->
    <div class="text-il-dark font-bold uppercase text-xs mt-8">
      Current Payment Method
    </div>

    <div v-if="!getUserX.defaultPayment">
      <span class="font-bold text-sm text-red-600">
        No saved payment method exists
      </span>
    </div>

    <div v-if="getUserX.defaultPayment">
      <span class="w-32 inline-block font-bold">
        Brand
      </span>
      <span>
        {{ getUserX.defaultPayment.brand }}
      </span>
    </div>

    <div v-if="getUserX.defaultPayment">
      <span class="w-32 inline-block font-bold">
        Card Ending
      </span>
      <span>
        {{ getUserX.defaultPayment.last4 }}
      </span>
    </div>

    <div
      v-if="getIsOnTrialX || isInGracePeriodMX(getUserX.trialEndsAt)<=0"
      class="text-red-600 my-1"
    >
      Your payment method will be billed after the trial or grace period ends
    </div>

    <div class="flex flex-wrap justify-left">
      <div
        v-if="!paymentDeleteLoading"
        @click="showPaymentMethodModal"
        class="mt-2 mr-2 rounded-lg bg-gray-300 text-gray-500 text-sm p-1 px-4 hover:bg-yellow-100 hover:text-yellow-700 transition-all duration-200 ease-in-out cursor-pointer"
      >
        <i class="far fa-credit-card"></i>
        <span class="ml-1">
          Update Payment Method
        </span>
      </div>

      <div
        v-if="getUserX.defaultPayment"
        @click="removePaymentMethod"
        class="mt-2 rounded-lg text-sm p-1 px-4  transition-all duration-200 ease-in-out"
        :class="paymentDeleteLoading ? 'bg-red-100 text-red-700 px-8' : 'bg-gray-300 text-gray-500 hover:bg-red-100 hover:text-red-700 cursor-pointer'"
      >
        <div v-if="!paymentDeleteLoading">
          <i class="fas fa-trash-alt"></i>
          <span class="ml-1">
            Remove Payment Method
          </span>
        </div>
        <div v-else>
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { add, format } from 'date-fns';
  import lodash from 'lodash';
  import SubscriptionTrialModal from './SubscriptionTrialModal';
  import CancelSubscriptionModal from './CancelSubscriptionModal';
  import PaymentMethodModal from './PaymentMethodModal';
  import userRequests from '../../helpers/userRequests';

  export default {
    mounted() {

    },
    data() {
      return {
        trialModalVisible: false,
        paymentMethodModalVisible: false,
        paymentDeleteLoading: false,
        cancelSubscriptionModalVisible: false,
        cancelTrialLoading: false
      }
    },
    components: {
      SubscriptionTrialModal,
      PaymentMethodModal,
      CancelSubscriptionModal
    },
    methods: {
      ...mapMutations([
        'setDefaultPaymentX',
        'setIsOnTrialX',
        'setTrialEndsAtX'
      ]),
      cancelTrial() {
        if(!this.cancelTrialLoading) {
          if (confirm('Are you sure you want to cancel the Trial?')) {
            this.cancelTrialLoading = true;
            userRequests.cancelTrial()
            .then((response) => {
              this.cancelTrialLoading = false;
              this.popSuccessMessageMX(response.data.message);
              this.setIsOnTrialX(false);
              this.setTrialEndsAtX(response.data.trial_ends_at);
            }).catch(error => {
              console.log(error);
              this.cancelTrialLoading = false;
            });
          }
        }
      },
      showTrialModal() {
        if(!this.getIsOnTrialX && this.getUserX.trialEndsAt && !this.getDefaultPaymentX) {
          this.popErrorMX('Please add a Payment Method first');
        } else {
          this.trialModalVisible = true;
        }
      },
      showPaymentMethodModal() {
        this.paymentMethodModalVisible = true;
      },
      showCancelSubscriptionModal() {
        this.cancelSubscriptionModalVisible = true;
      },
      removePaymentMethod() {
        if(this.getIsSubscribedX) {
          this.popErrorMX('Please cancel your subscription first');
        } else {
          if(!this.paymentDeleteLoading) {
            if (confirm('Are you sure you want to remove your Payment Method?')) {
              this.paymentDeleteLoading = true;
              userRequests.deletePaymentMethod()
              .then((response) => {
                this.paymentDeleteLoading = false;
                this.setDefaultPaymentX(response.data.default_payment);
                this.popSuccessMessageMX(response.data.message);
              }).catch(error => {
                this.paymentDeleteLoading = false;
              });
            }
          }
        }
      },
      oldestClientsForCompany() {
        return lodash.sortBy(this.getUserOldestCompanyX.clients, [function(client) {
          return client.created_at;
        }]);
        // lodash.forEach(this.getUserOldestCompanyX.clients, function(client) {
        //   if(!oldestCompany) {
        //     oldestCompany = company;
        //   } else {
        //     const d1 = new Date(oldestCompany.created_at);
        //     const d2 = new Date(company.created_at);

        //     if(d2 < d1) {
        //       oldestCompany = company;
        //     }
        //   }
        // });

        // return oldestCompany;
      }
      // resumeSubscription() {
      //   if(!this.getDefaultPaymentX) {
      //     alert("Please add a Payment Method first");
      //   } else {
      //     if(!this.subscriptionResumeLoading) {
      //       this.subscriptionResumeLoading = true;
      //       userRequests.resumeSubscription()
      //       .then((response) => {
      //         this.subscriptionResumeLoading = false;
      //         this.setIsSubscribedX(true);
      //         this.popSuccessMessageMX(response.data.message);
      //       }).catch(error => {
      //         this.subscriptionResumeLoading = false;
      //         this.popError(error.response.data.message);
      //       });
      //     }
      //   }
      // }
    },
    computed: {
      ...mapGetters([
        'getUserX',
        'getIsOnTrialX',
        'getIsSubscribedX',
        'getSubscribedBeforeX',
        'getDefaultPaymentX',
        'getNextPaymentDateX',
        'getUserOldestCompanyX'
      ]),
      trialEndDate() {
        return format(new Date(this.getUserX.trialEndsAt), 'do MMM, yyyy');
      },
      trialEndGraceDate() {
        return format(add(new Date(this.getUserX.trialEndsAt), {days: 3}), 'do MMM, yyyy');
      },
      oldestClientsForOldestCompany() {
        var oldestClients = lodash.sortBy(this.getUserOldestCompanyX.clients, [function(client) {
          return client.created_at;
        }]);
        // console.log(this.getUserOldestCompanyX.clients);
        return oldestClients;
      }
    },
  }
</script>